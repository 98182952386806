<template>
  <div
    style="
      position: relative;
      background-color: #fff3f0;
      min-width: 1600px;
      padding-top: 100px;
    "
  >
    <div
      class="barnner"
      :style="{
        background:
          'url(' + require('../../../static/newImage/development_bg.png') + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <el-image
        class="oneimg"
        :src="require('../../../static/newImage/development_title.png')"
        style="width: 1300px"
        width="1300px"
      ></el-image>
    </div>
    <div class="development_map">
      <div class="development_map_img">
        <el-image
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-26/87d98c091c30433bbcb547c8671632bd.png"
          width="880px"
        ></el-image>
      </div>
      <div class="development_map_choose"></div>
      <div class="development_map_choose_img">
        <el-image
          :src="require('../../../static/newImage/development_choose.png')"
          style="width: 46px"
        ></el-image>
      </div>
      <div class="development_map_line">
        <div class="development_map_line_1"></div>
        <div class="development_map_line_2"></div>
        <div
          class="development_map_line_top"
          style="z-index: 101; width: 880px; height: 375px"
        ></div>
        <el-image
          class="development_map_line_topX"
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-26/af784f6755914294bd4feee3a9967616.png"
          style="width: 878px"
        ></el-image>
        <div class="development_map_line_container1" v-show="playAm">
          <div class="earth-container">
            <div class="card card1"></div>
            <div class="card card2"></div>
            <div class="card card3"></div>
            <div class="card card4"></div>
            <div class="card card5"></div>
            <div class="card card6"></div>
            <div class="card card7"></div>
            <div class="card card8"></div>
            <div class="card card9"></div>
            <div class="card card10"></div>
          </div>
        </div>
        <div class="development_map_line_container1" v-show="!playAm">
          <div class="earth-container1">
            <div
              class="card card1"
              @click="roundMap(0)"
              :class="playAmIndex == 0 ? 'card1Active' : ''"
            ></div>
            <div
              class="card card2"
              @click="roundMap(1)"
              :class="playAmIndex == 1 ? 'card2Active' : ''"
            ></div>
            <div
              class="card card3"
              @click="roundMap(2)"
              :class="playAmIndex == 2 ? 'card3Active' : ''"
            ></div>
            <div
              class="card card4"
              @click="roundMap(3)"
              :class="playAmIndex == 3 ? 'card4Active' : ''"
            ></div>
            <div
              class="card card5"
              @click="roundMap(4)"
              :class="playAmIndex == 4 ? 'card5Active' : ''"
            ></div>
            <div
              class="card card6"
              @click="roundMap(5)"
              :class="playAmIndex == 5 ? 'card6Active' : ''"
            ></div>
            <div
              class="card card7"
              @click="roundMap(6)"
              :class="playAmIndex == 6 ? 'card7Active' : ''"
            ></div>
            <div
              class="card card8"
              @click="roundMap(7)"
              :class="playAmIndex == 7 ? 'card8Active' : ''"
            ></div>
            <div
              class="card card9"
              @click="roundMap(8)"
              :class="playAmIndex == 8 ? 'card9Active' : ''"
            ></div>
            <div
              class="card card10"
              @click="roundMap(9)"
              :class="playAmIndex == 9 ? 'card10Active' : ''"
            ></div>
            <div
              class="card card11"
              @click="roundMap(9)"
              :class="playAmIndex == 9 ? 'card10Active' : ''"
            ></div>
          </div>
        </div>
        <div class="development_map_text" v-show="!playAm">
          <div
            @click="roundMap(index)"
            :class="'development_map_text development_map_text_' + index"
            v-for="(item, index) in list.length + 1"
            :key="index"
          >
            {{ listName[index] }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="width: 1560px; padding: 0 20px; margin: 0 auto">
        <div
          class="development_bg"
          :style="
            'background-image: url(' + list[developmentIndex].image + ');'
          "
        >
          <div class="development_bg_title">
            <div
              style="
                font-size: 42px;
                color: #ffe7cf;
                text-align: center;
                letter-spacing: 4px;
                font-weight: bold;
              "
            >
              {{ listName1[developmentIndex] }}
            </div>
            <div style="font-size: 24px; margin-top: 50px; text-align: justify">
              {{ list[developmentIndex].content }}
            </div>
          </div>
        </div>
      </div>
      <div class="development_intro">
        <!-- <div
          class="development_intro_title"
          v-show="list[developmentIndex].content != '版块正在建设中……'"
        >
          企业简介
        </div>
        <div
          class="development_intro_content"
          v-show="list[developmentIndex].content != '版块正在建设中……'"
        >
          {{ list[developmentIndex].content }}
        </div> -->
        <div>
          <!-- 农业、数交网站 -->
          <div
            style="width: 1540px; margin: 0 auto"
            v-show="developmentIndex == 0 || developmentIndex == 9"
          >
            <div class="middle_new">
              <div class="middle_Left">
                <el-carousel
                  :interval="5000"
                  ref="middleCarousel"
                  arrow="never"
                  @change="carouselMid"
                >
                  <!-- @click="toDetails(item, index)" -->
                  <el-carousel-item
                    :style="`background-image: url(${item.newsCover});background-size: cover;`"
                    v-for="(item, index) in middle_journalism"
                    :key="index"
                  >
                    <div
                      style="min-width: 896px; height: 480px"
                      @click="toDetails(item, index)"
                    ></div>
                    <div class="mask" @click="toDetails(item, index)"></div>
                    <div class="middle_item" @click="toDetails(item, index)">
                      <div class="middle_font">
                        {{ item.newsTitle }}
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <div class="cornerMarkBoxMid">
                  <div
                    :class="midCk == index ? ' ckcss' : ''"
                    class="cornerMarkMid"
                    v-for="(item, index) in middle_journalism"
                    :key="index"
                    @click="cornerMid(index)"
                  >
                    <div style="color: #fff">{{ index + 1 }}</div>
                  </div>
                </div>
              </div>
              <div class="middle_Rigth">
                <!-- style="color:#157b2f;" -->
                <div
                  v-for="(item, index) in middle_journalism"
                  @click="toDetails(item, index)"
                  :key="index"
                >
                  <div
                    class="middle_Ritem moveTop"
                    @mouseover="mouseOverNews(index)"
                  >
                    <div class="middle_Rfont">{{ item.newsTitle }}</div>
                    <div
                      style="
                        line-height: 16px;
                        margin-bottom: 20px;
                        color: #8c8f92;
                      "
                    >
                      <div class="minddle_danwie">
                        {{ item.newsIntroduction || item.newsTitle }}
                      </div>
                      <div class="minddle_time">
                        {{ item.publishTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="middle_new">
              <div class="middle_new1">
                <div class="middle_new1_title">
                  {{ developmentIndex == 0 ? "媒体资讯" : "信息披露" }}
                </div>
                <div
                  class="middle_new1_content moveTop"
                  v-for="item in newsList1"
                  :key="item.newsId"
                  @click="toDetails(item)"
                >
                  · &nbsp;{{ item.newsTitle }}
                </div>
              </div>
              <div class="middle_new1">
                <div class="middle_new1_title">
                  {{ developmentIndex == 0 ? "行业资讯" : "新闻资讯" }}
                </div>
                <div
                  class="middle_new1_content moveTop"
                  v-for="item in newsList2"
                  :key="item.newsId"
                  @click="toDetails(item)"
                >
                  · &nbsp;{{ item.newsTitle }}
                </div>
              </div>
            </div>

            <div v-if="developmentIndex == 0">
              <div class="development_news_titleBg">
                <el-image
                  :src="
                    require('../../../static/newImage/development_news_title1.png')
                  "
                  style="width: 260px"
                  width="260px"
                ></el-image>
              </div>
              <div style="display: flex; justify-content: space-between">
                <div
                  class="development_block_left"
                  @click="toOther('https://www.zoneyu.cc/#/medicinalSector')"
                  style="cursor: pointer"
                >
                  <div class="development_block_content">
                    <div class="development_block_title">
                      药材版块
                      <div
                        style="position: absolute; right: 250px; bottom: 10px"
                      >
                        <el-image
                          class="one"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 12px; height: 16px"
                        ></el-image>
                        <el-image
                          class="two"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 12px; height: 16px"
                        ></el-image>
                        <el-image
                          class="three"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 12px; height: 16px"
                        ></el-image>
                      </div>
                    </div>
                    <div class="development_block_line"></div>
                    <div class="development_block_num">
                      <div
                        @click="
                          toOther('https://www.zoneyu.cc/#/medicinal?id=1')
                        "
                        style="cursor: pointer"
                      >
                        {{ statisticInfo.yaocai.plantedNumber }}<br /><span
                          class="development_block_num_tips"
                          >种植面积(亩)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.yaocai.projectPersonnel }}<br /><span
                          class="development_block_num_tips"
                          >工作人员(人)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.yaocai.projectBaseNumber }}<br /><span
                          class="development_block_num_tips"
                          >基地数量(个)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.yaocai.subordinateUnits }}<br /><span
                          class="development_block_num_tips"
                          >下属单位(家)</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="development_block_right"
                  @click="toOther('https://www.zoneyu.cc/#/EnergySector')"
                  style="cursor: pointer"
                >
                  <div class="development_block_content">
                    <div class="development_block_title">
                      能源版块
                      <div
                        style="position: absolute; right: 250px; bottom: 10px"
                      >
                        <el-image
                          class="one"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 12px; height: 16px"
                          width="12px"
                        ></el-image>
                        <el-image
                          class="two"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 11px; height: 16px"
                          width="11px"
                        ></el-image>
                        <el-image
                          class="three"
                          :src="
                            require('../../../static/newImage/development_triggle.png')
                          "
                          style="width: 11px; height: 16px"
                          width="11px"
                        ></el-image>
                      </div>
                    </div>
                    <div class="development_block_line"></div>
                    <div class="development_block_num">
                      <div
                        @click="
                          toOther('https://www.zoneyu.cc/#/medicinal?id=4')
                        "
                        style="cursor: pointer"
                      >
                        {{ statisticInfo.nengyuan.plantedNumber }}<br /><span
                          class="development_block_num_tips"
                          >覆盖面积(亩)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.nengyuan.projectPersonnel }}<br /><span
                          class="development_block_num_tips"
                          >工作人员(人)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.nengyuan.projectBaseNumber
                        }}<br /><span class="development_block_num_tips"
                          >基地数量(个)</span
                        >
                      </div>
                      <div>
                        <el-image
                          :src="
                            require('../../../static/newImage/development_news_line.png')
                          "
                          style="width: 1px; height: 58px"
                          width="1px"
                          height="58px"
                        ></el-image>
                      </div>
                      <div>
                        {{ statisticInfo.nengyuan.subordinateUnits }}<br /><span
                          class="development_block_num_tips"
                          >下属单位(家)</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="development_news_titleBg">
                <el-image
                  :src="
                    require('../../../static/newImage/development_news_title2.png')
                  "
                  style="width: 260px"
                  width="260px"
                ></el-image>
              </div>

              <div style="position: relative">
                <div style="width: 1540px; margin: 0 auto">
                  <div class="specific_field">
                    <div class="field_dom">
                      <div
                        :style="`background: url(${item.companyImage})`"
                        class="item_fieDom"
                        v-for="(item, index) in medicinalCompanyList"
                        :key="index"
                        @click="jumpcityCompany(item)"
                      >
                        <div class="mask"></div>
                        <div class="item_Dom" style="">
                          <div class="item_title">{{ item.companyName }}</div>
                          <div class="item_cent">
                            {{ item.companyDesc }}
                          </div>
                          <!-- <div class="jiaobioa">{{ index }}</div> -->
                          <div style="text-align: center; margin-top: 20px">
                            <el-image
                              :src="require('../../../static/newImage/L.png')"
                              alt=""
                              srcset=""
                            ></el-image>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="development_more">
              <div class="development_more_btn" @click="toHomePath()">
                点击进入官网
                <div style="position: relative; cursor: pointer">
                  <!-- @click="thereJunp(newsList[0], 22)" -->
                  <div style="position: absolute; right: 39%; bottom: 2px">
                    <img
                      class="one"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                    <img
                      class="two"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                    <img
                      class="three"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 不修改 -->
          <div v-show="developmentIndex == 6">
            <div class="development_more">
              <div class="development_more_btn" @click="toHomePath()">
                点击进入官网
                <div style="position: relative; cursor: pointer">
                  <!-- @click="thereJunp(newsList[0], 22)" -->
                  <div style="position: absolute; right: 39%; bottom: 2px">
                    <img
                      class="one"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                    <img
                      class="two"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                    <img
                      class="three"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-18/628c6f5bb33b4f3e8d474bf8a07788e6.png"
                      width="14px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="
              developmentIndex != 6 &&
              developmentIndex != 0 &&
              developmentIndex != 9
            "
          >
            <div class="development_more">
              <div class="development_more_btn">正在建设中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { Swiper } from "swiper";
import axios from "axios";
import { getAccessToken, getSignature } from "@/api/index";
// import "echarts-gl"
export default {
  name: "industrial_development",

  data() {
    return {
      listName: [
        "中峪农业",
        "中峪交易市场",
        "中峪学校",
        "金生投资",
        "中峪商贸",
        "养老版块",
        "养老版块",
        "麒典信息科技",
        "医院版块",
        "制药版块",
        "中峪数交",
      ],
      listName1: [
        "浙江中峪农业发展有限责任公司",
        "浙江中峪日用品交易市场有限公司",
        "绍兴中峪职业技能培训学校有限责任公司",
        "丽水金生投资管理有限责任公司",
        "四川中峪商贸服务有限公司",
        "养老版块",
        "浙江麒典信息科技有限公司",
        "医院版块",
        "制药版块",
        "四川中峪拍卖交易服务有限责任公司",
      ],
      list: [
        {
          id: "6",
          title: "中峪农业",
          content:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。 中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。",
          image: require("@/assets/image/2-banner/yaocai.png"),
          bgImage: require("../../../static/newImage/nongye.png"),
        },
        {
          id: "1",
          title: "中峪交易市场",
          content:
            "中峪日用品交易市场均为中峪集团十大闭环产业之一，具有多行业背景和资源优势，整合融通了多品种商品资源、资产数字化运作、仓储物流、资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，帮助中小企业实现去库存、优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+金融服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为中峪道地药材产业链发展建立资产数字化交易平台，为线下实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。由交易市场和中峪拍交所赋能，成功开启道地药材线上线下创新融合，从中药材种子种苗溯源、带量采购、中草药制剂研发到医院养老产业端口输出，助推集团各个产业链的良性闭环。",
          image: require("@/assets/image/2-banner/jiaoyi.jpg"),
          bgImage: require("../../../static/newImage/jiaoyishichang.png"),
        },
        {
          id: "3",
          title: "中峪学校",
          content:
            "中峪职业培训学校是一家结合国家职业教育体系改革指导政策，肩负起特色职业技能培育，为中峪产业培育人才，给社会输送专业人才，同时解决就业再就业问题，为地方职业教育发展提供助力，为养老和文化产业发展提供助力。",
          image: require("@/assets/image/2-banner/xuexiao.jpg"),
          bgImage: require("../../../static/newImage/xuexiao.png"),
        },
        {
          id: "4",
          title: "金生投资",
          content:
            "投资管理公司不仅仅为各产业提供金融助力，更是为了集团进一步发展服务，在集团稳健发展的基础上，进行分层级的资本良性运作，为社会创造更多的财富，为共同富裕助力。",
          image: require("@/assets/image/2-banner/touzi.jpg"),
          bgImage: require("../../../static/newImage/touzi.png"),
        },
        {
          id: "5",
          title: "中峪商贸",
          content:
            "中峪商贸为四川省泸州市地方政府重点招商引资项目，是一家承载孵化落地集团全产业链使命的运营公司，以其独特商业运营架构和科学创新的市场培育体系，助推地方中小企业产品流通，促进地方区域经济发展，优化商品流通渠道。协同中峪拍交所为集团产业导入高粘度市场流量，协同中峪职业培训学校共同孵化输送业务体系人才，为地方解决就业、创业而努力。",
          image: require("@/assets/image/2-banner/shangmao.jpg"),
          bgImage: require("../../../static/newImage/shangmao.png"),
        },
        {
          id: "10",
          title: "养老版块",
          content: "版块正在建设中……",
          image:
            "http://bizpub.oss-cn-hangzhou.aliyuncs.com/30c0338582b04652be40f3cad042c7df养老w.jpg",
          bgImage: require("../../../static/newImage/yanglao.png"),
        },
        {
          id: "7",
          title: "麒典信息科技",
          content:
            "麒典信息科技是一家集大数据服务、核心技术研发、技术咨询服务、区块链技术应用、软件开发等科技研发公司，为集团产业链数字化运作提供强大技术输出，肩负集团产业链大数据信息储存输送和科技数字化运作重任，同时为助力地方产业数字化发展提供强大技术支撑，承载银发经济链产品研发和制造。",
          image: require("@/assets/image/2-banner/xinxi.jpg"),
          bgImage: require("../../../static/newImage/xinxikeji.png"),
        },
        {
          id: "8",
          title: "医院版块",
          content: "版块正在建设中……",
          image:
            "http://bizpub.oss-cn-hangzhou.aliyuncs.com/ca8e0b6b57b342e7a253c538e45f929d医院.jpg",
          bgImage: require("../../../static/newImage/yiyuan.png"),
        },
        {
          id: "9",
          title: "制药版块",
          content:
            "生物制药坚持中医药原创思维，深入发掘中医药精华，是一家传承古代经典名方、名老中医经验方、传统民族药业、新药研发为一体的高新技术制药研发公司。为传承中医药文化，促进中医药特色发展，提升中医药产业水平做出积极贡献，是中峪道地药材的制药基地，是中峪医院的药材基地，为中峪康养产业提供优质药食同源。",
          image: require("@/assets/image/2-banner/yiyuan.jpg"),
          bgImage: require("../../../static/newImage/zhiyaobankuai.png"),
        },
        {
          id: "2",
          title: "中峪数交",
          content:
            "四川中峪拍卖交易服务有限责任公司简称“中峪数交”，为中峪集团生态内循环闭环产业链之一，具有多行业背景和资源优势，整合融通了多品种商品资源、数字化拍卖服务、仓储物流资源和资讯服务平台，充分结合集团闭环产业优势，为大众创业、万众创新服务，实现跨界融合，优化产能拉动内需，以“发展产业电商、服务实体经济”为使命，积极响应国家乡村振兴、数字中国建设、数商兴农号召，以“产业基地+在线贸易+科技应用+数字拍卖服务”为功能定位，构建产业链生态闭环，致力于产业创新服务综合体建设，为实现数字经济新农业，乡村振兴新载体发展起到强大支撑和赋能，同时发挥资产数字化拍卖平台的优势，为实体产业赋能创收和大数据客户引流扩容，为加快推进数字时代促进数字经济和实体经济深度融合尽一份责任。",
          image: require("@/assets/image/2-banner/jiaoyi.jpg"),
          bgImage: require("../../../static/newImage/shujiao.png"),
        },
      ],
      developmentIndex: 0,
      newsList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      newsList5: [],
      newsList6: [],
      newsImageIndex: 0,
      playAm: false,
      playAmIndex: 0,
      doNothing: 0,
      middle_journalism: [],
      midCk: 0,
      statisticInfo: {
        yaocai: {},
        nengyuan: {},
      },
      medicinalCompanyList: [
        {
          companyId: 19,
          companyName: "浙江中峪道地药材有限公司",
          websiteId: 3,
          companyDesc:
            "浙江中峪道地药材有限公司属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。",
          companyDirector: "赵月萍",
          companyImage:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-30/b7b2fe9ab1f3466996c9d964798e9b9c.jpg",
          pid: 3,
          fullCompanyId: ":3::19:",
          companyLevel: 2,
          companyLocation: "浙江省",
          listingStatus: 1,
          companyCategory: 0,
          adCode: "330000",
          lxy: '["120.55","30.29"]',
          isDelete: false,
          createTime: "2024-01-19 13:40:42",
          createBy: null,
          updateTime: "2024-09-30 14:45:12",
          updateBy: null,
          website: null,
        },
        {
          companyId: 49,
          companyName: "浙江中峪药风光能源开发有限公司",
          websiteId: 3,
          companyDesc:
            "浙江中峪药风光能源开发有限公司是一家专注于新能源开发与利用的企业。为深入贯彻落实党中央、国务院实施乡村振兴战略决定部署，按照建设现代能源体系和推进农业农村现代化要求，加快实施【国家能源局 农业农村部 国家乡村振兴局关于印发《加快农村能源转型发展助力乡村振兴的实施意见》的通知》（国能发规划〔2021〕66 号）实现碳达峰、碳中和农业农村现代化目标，实施乡村振兴暨实现农村地区能源绿色转型发展。公司以新能源开发和中药材种植相融合发展的创新模式，配合集团设施农业技术（中药材种植农场），新能源发电就地消纳，利用综合智慧数字管理平台的综合智控，打造农 光 碳互补的综合智慧零碳种植农场，目前公司已经规划部署在多省打造“乡村振兴，药风光，零碳共富产业园”，成为助力实现乡村振兴，共同富裕，国家双碳的践行企业。",
          companyDirector: "陈红良",
          companyImage:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/32d4d2f6c848456da4306426edde15b6.jpeg",
          pid: 3,
          fullCompanyId: ":3::49:",
          companyLevel: 2,
          companyLocation: "浙江省",
          listingStatus: 1,
          companyCategory: 1,
          adCode: "330000",
          lxy: '["120.55","30.29"]',
          isDelete: false,
          createTime: "2024-03-01 16:19:11",
          createBy: null,
          updateTime: "2024-09-29 17:03:54",
          updateBy: null,
          website: null,
        },
      ],
      newId: null,
      isMobile: false,
    };
  },
  filters: {
    enterText(text) {
      const textArr = text.split("");
      return textArr.join("/n");
    },
  },
  watch: {
    "$route.query.id": function (newId, oldId) {
      console.log(newId, oldId, "9999999999999999999999");
      window.scrollTo(0, 0);
      if (newId) {
        const id = newId;
        const index = this.list.findIndex((item) => {
          if (item.id == id) {
            console.log(item, item.id);
          }
          return item.id == id;
        });
        let title;
        this.list.forEach((res) => {
          if (res.id == id) {
            console.log(res, id);
            title = res.title;
          }
        });
        console.log(title);
        this.developmentIndex = index;
        this.listName.splice(5, 1);
        // let clickArr = [];
        // const clickArr = this.listName.splice(index, this.listName.length);
        const clickArr = this.listName.splice(index, this.listName.length);
        // const clickArr = this.listName.splice(index, 1);
        console.log(
          this.listName,
          clickArr,
          "1",
          index,
          this.listName.length - 2
        );
        const beforeArr = this.listName.splice(0, index);
        console.log(beforeArr);

        const newArr = clickArr.concat(beforeArr);
        console.log(newArr);

        newArr.unshift(title);
        console.log(newArr);

        let uniqueArr = [...new Set(newArr)];
        console.log(uniqueArr);

        // let fourthElement = uniqueArr[4];
        // console.log(fourthElement);

        // uniqueArr.splice(4, 0, fourthElement);
        console.log(uniqueArr, 8511);

        this.listName = [
          uniqueArr[0],
          uniqueArr[1],
          uniqueArr[2],
          uniqueArr[3],
          uniqueArr[4],
          uniqueArr[5],
          uniqueArr[5],
          uniqueArr[6],
          uniqueArr[7],
          uniqueArr[8],
          uniqueArr[9],
        ];

        // this.listName = [
        //   newArr[0],
        //   newArr[1],
        //   newArr[2],
        //   newArr[3],
        //   newArr[4],
        //   newArr[5],
        //   newArr[5],
        //   newArr[6],
        //   newArr[7],
        //   newArr[8],
        //   newArr[9],
        // ];

        let arr = [];
        if (id == 6) {
          this.list.forEach((e) => {
            if (e.title == "中峪农业") {
              arr = this.listName.map((x) => {
                if (!x) {
                  return "中峪数交";
                }
                return x;
              });
            }
          });
          this.listName = arr;
        }
      }
      console.log(
        this.listName,
        this.developmentIndex,
        this.list[this.developmentIndex].id
      );
      // this.playAmIndex = this.developmentIndex;
      if (this.$refs.newsImgCarousel) {
        this.slideBanner();
      }
      this.changeNewsIndex();
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    window.scrollTo(0, 0);
    if (
      document.getElementsByClassName("nav_1")[0] &&
      document.getElementsByClassName("nav_1")[0].parentElement.tagName ==
        "BODY"
    ) {
      document.getElementsByClassName("nav_1")[0].remove();
    }
    // 判断是否是手机设备
    const reg =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    this.isMobile = reg.test(navigator.userAgent);
    console.log(this.isMobile, 9951);
    if (this.isMobile) {
      localStorage.setItem("device", this.isMobile);
      const element = document.querySelector(".development_map_line_topX");
      element.style.width = "883px";
      element.style.top = "26%";
      element.style.left = "49.9%";
      const elementX = document.querySelector(".development_map");
      elementX.style.paddingTop = "400px";
      const oneimg = document.querySelector(".oneimg");
      oneimg.style.top = "45%";
    } else {
      localStorage.setItem("device", this.isMobile);
      // document.getElementById("app").style = "transform: scale(0.85)";
    }
    if (this.$route.query.id) {
      const id = this.$route.query.id;
      const index = this.list.findIndex((item) => {
        return item.id == id;
      });
      this.developmentIndex = index;
      console.log(this.listName, this.developmentIndex);
      this.listName.splice(5, 1);
      const clickArr = this.listName.splice(index, this.listName.length - 1);
      const beforeArr = this.listName.splice(0, index);
      const newArr = clickArr.concat(beforeArr);

      this.listName = [
        newArr[0],
        newArr[1],
        newArr[2],
        newArr[3],
        newArr[4],
        newArr[5],
        newArr[5],
        newArr[6],
        newArr[7],
        newArr[8],
        newArr[9],
      ];
      let arr = [];
      if (id == 6) {
        this.list.forEach((e) => {
          if (e.title == "中峪农业") {
            arr = this.listName.map((x) => {
              if (!x) {
                return "中峪数交";
              }
              return x;
            });
          }
        });
        this.listName = arr;
      }
    }
    if (this.$refs.newsImgCarousel) {
      this.slideBanner();
    }
    this.changeNewsIndex();

    this.getWXInfo();
  },
  beforeDestory() {},
  methods: {
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.data.timestamp;
        const nonceStr = res.data.data.noncestr;
        const signature = res.data.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "中峪控股产业", // 分享标题
            desc: "立民族志气 创世界品牌", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/7174b29954a34f7b9c22cc0ad7714434.jpg", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    jumpcityCompany(item) {
      const companyId = item.companyId;
      window.open(
        `https://www.zoneyu.cc/#/ProvincialCompany?companyId=${companyId}&companyArr=%5B3,${companyId}%5D`
      );
    },
    toOther(url) {
      window.open(url);
    },
    cornerMid(item) {
      this.midCk = item;
      this.$refs.middleCarousel.setActiveItem(item);
    },
    carouselMid(e) {
      console.log(e);
      this.midCk = e;
    },
    mouseOverNews(index) {
      this.midCk = index;
      this.$refs.middleCarousel.setActiveItem(index);
    },
    roundMap(index) {
      console.log(index);
      if (index !== 0) {
        this.playAm = true;
        setTimeout(() => {
          this.playAm = false;
          this.playAmIndex = index;
          const name = this.listName[index];
          const indexChoose = this.list.findIndex((val) => val.title == name);
          this.developmentIndex = indexChoose;
          if (this.developmentIndex == 0 || this.developmentIndex == 9) {
            this.changeNewsIndex();
          }
          if (index > 5) {
            index = index - 1;
          }
          this.listName.splice(5, 1);
          const clickArr = this.listName.splice(
            index,
            this.listName.length - 1
          );
          const beforeArr = this.listName.splice(0, index);
          const newArr = clickArr.concat(beforeArr);
          this.listName = [
            newArr[0],
            newArr[1],
            newArr[2],
            newArr[3],
            newArr[4],
            newArr[5],
            newArr[5],
            newArr[6],
            newArr[7],
            newArr[8],
            newArr[9],
          ];
        }, 200);
      }
    },
    toDetails(item) {
      if (this.developmentIndex == 9) {
        window.open(
          `https://sj.zoneyu.net/newsListDetail?id=${item.newsId}&type=新闻资讯`
        );
      }
      if (this.developmentIndex == 0) {
        window.open(
          `https://www.zoneyu.cc/#/newsListDetail?id=${item.newsId}&type=${item.websiteNames}&index=0`
        );
      }
    },
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(
        ".newsImgCarousel .el-carousel__container"
      );
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.newsImgCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.newsImgCarousel.prev();
          return;
        }
      });
    },
    changeNewsImgCarousel(index) {
      this.newsImageIndex = index;
    },
    changeNewsImageIndex(index) {
      this.newsImageIndex = index;
      this.$refs.newsImgCarousel.setActiveItem(index);
    },
    changeNewsIndex() {
      const id = this.list[this.developmentIndex].id;
      let websiteCategoryId;
      if (id == 2) {
        websiteCategoryId = 9;
      } else if (id == 6) {
        websiteCategoryId = 18;
      }
      this.newsList1 = [];
      this.newsList2 = [];
      this.newsList3 = [];
      this.newsList4 = [];
      this.newsList5 = [];
      this.newsList6 = [];
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=50&websiteCategoryId=` +
            websiteCategoryId
        )
        .then(async (res) => {
          if (res.data.data) {
            this.middle_journalism = res.data.data.list.slice(0, 3);
          }
        });
      // 农业
      if (this.developmentIndex == 0) {
        axios
          .get(
            `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=4&websiteId=3&self=1&websiteCategoryId=38`
          )
          .then(async (res) => {
            this.newsList1 = res.data.data.list;
          });
        axios
          .get(
            `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=4&websiteId=3&self=1&websiteCategoryId=35`
          )
          .then(async (res) => {
            this.newsList2 = res.data.data.list;
          });

        axios
          .get(`https://admin.zoneyu.cn/cms/api/app/company/3/statistic`)
          .then(async (res) => {
            const data = res.data.data;
            this.statisticInfo = {
              yaocai: {
                plantedNumber: data[0].plantedNumber + data[2].plantedNumber,
                projectBaseNumber:
                  data[0].projectBaseNumber + data[2].projectBaseNumber,
                projectPersonnel:
                  data[0].projectPersonnel + data[2].projectPersonnel,
                subordinateUnits:
                  data[0].subordinateUnits + data[2].subordinateUnits,
              },
              nengyuan: {
                plantedNumber: data[1].plantedNumber + data[2].plantedNumber,
                projectBaseNumber:
                  data[1].projectBaseNumber + data[2].projectBaseNumber,
                projectPersonnel:
                  data[1].projectPersonnel + data[2].projectPersonnel,
                subordinateUnits:
                  data[1].subordinateUnits + data[2].subordinateUnits,
              },
            };
          });
      }
      // 数交
      if (this.developmentIndex == 9) {
        axios
          .get(
            `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=4&websiteCategoryId=8`
          )
          .then(async (res) => {
            this.newsList1 = res.data.data.list;
          });
        axios
          .get(
            `https://admin.zoneyu.cn/cms/api/app/news/list?current=1&size=4&websiteCategoryId=9`
          )
          .then(async (res) => {
            this.newsList2 = res.data.data.list;
          });
      }
    },
    toHomePath() {
      const id = this.list[this.developmentIndex].id;
      if (id == 2) {
        window.open("https://sj.zoneyu.net/");
      } else if (id == 6) {
        window.open("https://www.zoneyu.cc/");
      } else if (id == 7) {
        window.open("https://www.zoneyu.com.cn/");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.specific_field {
  position: relative;

  .field_dom {
    display: flex;
    flex-wrap: wrap;
    // width: 1900px;

    justify-content: space-between;

    .item_fieDom:hover {
      .item_Dom {
        transform: translateY(0);
        transition: all 0.5s;
      }

      .item_title {
        margin-bottom: 25px !important;
      }
    }

    .item_fieDom {
      background-size: cover !important;
      cursor: pointer;
      box-shadow: 0px 2px 11px 0 (53, 74, 50, 0.1);
      position: relative;
      height: 400px;
      width: 750px;
      overflow: hidden;
      border-radius: 10px;
      // flex: 0 48%;

      .mask {
        width: 750px;
        height: 300px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }

      /* 每个项目占据一行宽度的1/3 */
      .item_Dom {
        position: absolute;
        width: 750px;
        height: 340px;
        bottom: 0;
        transform: translateY(140px);
        z-index: 2;

        //   box-shadow: 0px 2px 31px 0px rgba(21, 123, 47, 0.18);
        .item_title {
          margin-top: 98px;
          margin-bottom: 55px;
          font-weight: 900;
          font-size: 40px;
          color: #ffffff;
          line-height: 42px;
          letter-spacing: 1px;
          text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
          text-align: center;
          letter-spacing: 4px;
        }

        .item_cent {
          height: 93px;
          overflow: hidden;
          text-overflow: ellipsis;
          // 只要超过宽度就换行，不论中文还是英文
          word-break: break-all;
          //最多展示两行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          opacity: 0.8;
          font-weight: 400;
          margin: 0 80px;
          font-size: 16px;
          color: #ffffff;
          line-height: 31px;
          text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
          text-align: left;
          font-style: normal;
        }

        .jiaobioa {
          text-align: center;
        }
      }
    }
    .item_fieDom::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
      z-index: 1;
    }

    .item_fieDom:nth-child(1) {
      // margin-right: 40px;
      margin-bottom: 40px;
    }
  }
}

.development_news_titleBg {
  background: url(../../../static/newImage/development_news_head.png) no-repeat;
  background-size: 80%;
  background-position: 50% 40%;
  text-align: center;
  margin: 100px 0 60px 0;
}
.development_block_left {
  position: relative;
  width: 750px;
  height: 380px;
  background: url(../../../static/newImage/development_news_bankuai1.png)
    no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.development_block_right {
  position: relative;
  width: 750px;
  height: 380px;
  background: url(../../../static/newImage/development_news_bankuai2.png)
    no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.development_block_content {
  margin: 90px 80px;
  position: relative;
  z-index: 2;
  .development_block_title {
    font-weight: bold;
    font-size: 51px;
    color: #fff5e4;
    letter-spacing: 18px;
    position: relative;
    .one {
      opacity: 0;
      margin-right: 10px;
      animation: fadeInFadeOut 1.5s ease-in-out 0s infinite;
    }
    .two {
      opacity: 0;
      margin-right: 10px;
      animation: fadeInFadeOut 1.5s ease-in-out 0.4s infinite;
    }
    .three {
      opacity: 0;
      margin-right: 10px;
      animation: fadeInFadeOut 1.5s ease-in-out 0.8s infinite;
    }
    @keyframes fadeInFadeOut {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .development_block_line {
    width: 600px;
    height: 1px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.53;
    margin-top: 36px;
  }
  .development_block_num {
    margin-top: 50px;
    display: flex;
    font-weight: bold;
    font-size: 28px;
    color: #ffc47f;
    line-height: 30px;
    justify-content: space-between;
    & > div {
      text-align: center;
    }
  }
  .development_block_num_tips {
    font-size: 14px;
    color: #fff4e7;
    line-height: 21px;
    letter-spacing: 1px;
    font-weight: 400;
  }
}

.development_block_left::before,
.development_block_right::before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    top,
    rgba(25, 21, 17, 0.88),
    rgba(62, 48, 34, 0.5)
  );
  opacity: 0.88;
  z-index: 1;
  border-radius: 20px;
}
.middle_new {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  .middle_new1 {
    width: 750px;
    height: 360px;
    background-image: url(../../../static/newImage/development_newsBg1.png);
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    .middle_new1_title {
      font-size: 33px;
      color: #bc2e2e;
      line-height: 45px;
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: bold;
    }
    .middle_new1_content {
      line-height: 55px;
      padding: 0 50px;
      text-align: left;
      cursor: pointer;
      width: calc(100% - 100px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
    }
    .moveTop:hover {
      color: #c51722;
    }
  }

  .middle_Left {
    .mask {
      min-width: 896px;
      height: 250px;
      bottom: 0;
      z-index: 10;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.83) 100%
      );
    }

    cursor: pointer;
    min-width: 896px;
    height: 480px;
    position: relative;

    .cornerMarkBoxMid {
      right: 30px;
      bottom: 24px;
      z-index: 8;
      position: absolute;
      display: flex;

      .cornerMarkMid {
        cursor: pointer;
        width: 36px;
        height: 36px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        font-size: 18px;
        // color: #fff;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.7);
        // opacity: 0.7;
        margin: 0 4px;
      }

      .ckcss {
        background: #c51722;

        // background: #412f1b;
        font-weight: bold;
      }
    }

    .middle_item {
      min-width: 896px;
      height: 480px;

      .middle_font {
        font-family: 宋体;
        z-index: 11;
        position: absolute;
        bottom: 24px;
        margin-left: 29px;
        width: 638px;
        overflow: hidden;
        font-weight: 900;
        font-size: 28px;
        color: #ffffff;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .middle_Rigth {
    .middle_Ritem:hover {
      color: #c51722 !important;
    }

    overflow: hidden;
    height: 480px;
    width: 620px;

    .middle_Ritem {
      cursor: pointer;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 30px;
      border-bottom: 1px solid #d8d8d8;
    }

    .middle_Rfont {
      width: 580px;
      margin-bottom: 19px;
      overflow: hidden;
      font-weight: bold;
      font-size: 22px;
      line-height: 42px;
      letter-spacing: 1px;
      text-align: left;
      font-style: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .minddle_time {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      margin-top: 20px;
    }

    .minddle_danwie {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 20px;
      width: 359px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      text-align: left;
      font-style: normal;
    }
  }

  .cornerMarkBox {
    z-index: 8;
    position: absolute;
    width: 180px;
    display: flex;
    bottom: 64px;
    right: 66px;
    cursor: pointer;
    justify-content: flex-end;

    .cornerMark {
      width: 36px;
      height: 36px;
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      margin-left: 10px;

      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.45);
    }

    .ckcss {
      background: rgba(65, 47, 27, 0.8);
      font-weight: bold;
      font-size: 18px;
    }
  }

  ::v-deep .el-carousel__indicators {
    display: none !important;
  }

  ::v-deep .el-carousel__container {
    height: 480px;
  }
}

.barnner {
  width: 100%;
  height: 100vw/ (1920/1087);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  min-width: 1540px;

  .oneimg {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.development_map {
  width: 100%;
  height: 1052px;
  z-index: 10;
  position: relative;
  padding-top: 520px;

  .development_map_img {
    margin: 0 auto;
    width: 1200px;
    text-align: center;
  }

  .development_map_choose {
    margin: 50px auto;
    width: 404px;
    height: 173px;
    background-image: url("../../../static/newImage/development_choose1.png");
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(2);
  }

  .development_map_choose_img {
    margin: 0 auto;
    width: 46px;
    transform: translate(0, -155px);
  }

  .development_map_line {
    position: absolute;
    // top: 60%;
    top: 54.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1400px;
    height: 800px;
    text-align: center;

    .development_map_line_1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -35%);
      width: 100%;
      height: 500px;
      border: 2px dashed #a4917a;
      opacity: 0.15;
      border-radius: 50%;
    }

    .development_map_line_2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      width: 100%;
      height: 500px;
      border: 2px dashed #a4917a;
      border-radius: 50%;
    }
    .development_map_line_topX {
      position: absolute;
      top: 31%;
      left: 49.93%;
      transform: translate(-50%, -50%);
      z-index: 99;
      background-color: transparent;
    }
    .development_map_line_top {
      position: absolute;
      top: 24.9%;
      left: 49.93%;
      transform: translate(-50%, -50%);
      z-index: 99;
      // top: 26%;
      // left: 49.9%;
      background-color: transparent;
    }
    .development_map_line_top > img {
      width: 880px;
    }
    .development_map_line_container1 {
      padding-top: 70px;
      height: 600px;
      width: 100%;
      perspective: 1200px;
      perspective-origin: center -200px;

      .earth-container {
        position: relative;
        width: 100%;
        height: 100%;
        animation: earth-rotation 1s linear infinite;
        transform-style: preserve-3d;
        transform-origin: center;

        .card {
          position: absolute;
          width: 100px;
          height: 147px;
          line-height: 100px;
          text-align: center;
          font-size: 16px;
          border-radius: 10px;
          transform-origin: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .card1 {
          transform: rotateY(0deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_yl1.png");
        }

        .card2 {
          transform: rotateY(36deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_ry1.png");
        }

        .card3 {
          transform: rotateY(72deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_xx1.png");
        }

        .card4 {
          transform: rotateY(108deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_tz1.png");
        }

        .card5 {
          transform: rotateY(144deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_sm1.png");
        }

        .card6 {
          transform: rotateY(180deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_ny1.png");
        }

        .card7 {
          transform: rotateY(216deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_qd1.png");
        }

        .card8 {
          transform: rotateY(252deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_yy1.png");
        }

        .card9 {
          transform: rotateY(288deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_zy1.png");
        }

        .card10 {
          transform: rotateY(324deg) translateZ(520px);
          background-image: url("../../../static/newImage/development_sj1.png");
        }
      }

      .earth-container1 {
        position: relative;
        width: 100%;
        height: 100%;

        .card {
          cursor: pointer;
        }

        .card1 {
          position: absolute;
          left: 43%;
          top: 95%;
          width: 180px;
          height: 291px;
          background: url("../../../static/newImage/development_map_bg.png")
            no-repeat;
          background-size: cover;
        }

        .card2 {
          position: absolute;
          left: 23%;
          top: 92%;
          width: 176px;
          height: 262px;
          background: url("../../../static/newImage/development_left1.png")
            no-repeat;
          background-size: cover;
        }

        .card9 {
          position: absolute;
          right: 23%;
          top: 92%;
          width: 176px;
          height: 262px;
          background: url("../../../static/newImage/development_right1.png")
            no-repeat;
          background-size: cover;
        }

        .card3 {
          position: absolute;
          left: 6%;
          top: 75%;
          width: 125px;
          height: 244px;
          background: url("../../../static/newImage/development_right5.png")
            no-repeat;
          background-size: cover;
        }

        .card8 {
          position: absolute;
          right: 6%;
          top: 75%;
          width: 125px;
          height: 244px;
          background: url("../../../static/newImage/development_left5.png")
            no-repeat;
          background-size: cover;
        }

        .card4 {
          position: absolute;
          left: -2%;
          top: 57%;
          width: 52px;
          height: 237px;
          background: url("../../../static/newImage/development_left3.png")
            no-repeat;
          background-size: cover;
        }

        .card7 {
          position: absolute;
          right: -2%;
          top: 57%;
          width: 52px;
          height: 237px;
          background: url("../../../static/newImage/development_right3.png")
            no-repeat;
          background-size: cover;
        }

        .card5 {
          position: absolute;
          left: 5%;
          top: 34%;
          width: 62px;
          height: 149px;
          background: url("../../../static/newImage/development_left4.png")
            no-repeat;
          background-size: cover;
        }

        .card6 {
          position: absolute;
          right: 5%;
          top: 34%;
          width: 62px;
          height: 149px;
          background: url("../../../static/newImage/development_right4.png")
            no-repeat;
          background-size: cover;
        }

        .card10 {
          position: absolute;
          left: 16%;
          top: 29%;
          width: 60px;
          height: 102px;
          background: url("../../../static/newImage/development_right2.png")
            no-repeat;
          background-size: cover;
        }

        .card11 {
          position: absolute;
          right: 16%;
          top: 29%;
          width: 60px;
          height: 102px;
          background: url("../../../static/newImage/development_left2.png")
            no-repeat;
          background-size: cover;
        }
      }

      @keyframes earth-rotation {
        from {
          transform: rotateY(0deg);
        }

        to {
          transform: rotateY(360deg);
        }
      }
    }

    .development_map_text {
      z-index: 98;
      font-weight: bold;
      font-size: 28px;
      color: #8b796a;
      line-height: 29px;
      letter-spacing: 2px;
      cursor: pointer;

      .development_map_text {
        writing-mode: vertical-lr;
        /* 从右到左 */
        text-orientation: upright;
        /* 保持正常显示 */
      }

      .development_map_text_0 {
        position: absolute;
        left: 43%;
        top: 85%;
        height: 226px;
        width: 178px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 31px;
        color: #ffeed9;
        line-height: 34px;
        letter-spacing: 3px;
      }

      .development_map_text_1,
      .development_map_text_10 {
        position: absolute;
        right: 23%;
        top: 82%;
        width: 176px;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
      }

      .development_map_text_10 {
        left: 23%;
      }

      .development_map_text_2,
      .development_map_text_9 {
        position: absolute;
        font-size: 21px;
        right: 4%;
        top: 72%;
        width: 175px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
      }

      .development_map_text_9 {
        left: 4%;
      }

      .development_map_text_3,
      .development_map_text_8 {
        position: absolute;
        font-size: 14px;
        right: -6.5%;
        top: 57%;
        width: 175px;
        height: 140px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .development_map_text_8 {
        left: -6.5%;
      }

      .development_map_text_4,
      .development_map_text_7 {
        position: absolute;
        font-size: 12px;
        right: 1%;
        top: 37.5%;
        width: 175px;
        height: 100px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
      }

      .development_map_text_7 {
        left: 1%;
      }

      .development_map_text_5,
      .development_map_text_6 {
        position: absolute;
        font-size: 12px;
        right: 12%;
        top: 31%;
        width: 175px;
        height: 100px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
      }

      .development_map_text_6 {
        left: 12%;
      }
    }
  }
}

@media screen and (max-device-width: 600px) {
  .barnner {
    height: 620px;
  }

  .development_map_line {
    transform: translate(-50%, -50%) scale(0.8) !important;
  }

  .development_map_img {
    transform: scale(0.8) !important;
  }

  .development_map_choose {
    margin: 0 auto !important;
    transform: translate(0, -40px) scale(1.2);
  }
}

.development_bg {
  width: 100%;
  height: 600px;
  margin: 70px 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 18px;

  .development_bg_title {
    font-size: 59px;
    color: #ffffff;
    line-height: 53px;
    text-align: left;
    font-style: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1400px;
  }
}

.development_bg::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(88, 48, 11, 0.7));
  border-radius: 18px;
}

.development_intro {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 50%;
  width: 100%;

  .development_intro_title {
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    color: #000000;
    font-style: normal;
  }

  .development_intro_content {
    font-weight: 500;
    font-size: 32px;
    color: #000000;
    line-height: 61px;
    font-style: normal;
    width: 1560px;
    padding: 0 20px;
    margin: 60px auto 0 auto;
  }

  .development_news_bg {
    width: 1200px;
    padding: 0 20px;
    margin: 40px auto;
    position: relative;

    .development_news_bg_bottom {
      position: absolute;
      bottom: 0;
      left: 20px;
      z-index: 2;
      color: #fff;
      padding: 0 16px;
      font-weight: bold;
      width: calc(100% - 72px);
      height: 242px;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: flex-end;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

      .development_news_bg_bottom_title {
        cursor: pointer;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
      }

      .development_news_bg_bottom_Index {
        float: left;
        color: #354a32;
        width: 20px;
        height: 18px;
        position: relative;
        line-height: 18px;
        border: 1px solid #ffffff;
        text-align: center;
        margin-right: 5px;
        cursor: pointer;
        background: #ffffff9a;
      }

      .development_news_bg_bottom_Index:hover {
        color: #fff;
        background: #354a32;
        border: 1px solid #354a32;
      }

      .active {
        color: #fff;
        background: #354a32;
        border: 1px solid #ffffff;
      }
    }

    .newsImgCarousel {
      .newsImgCarousel_black {
        width: 1200px;
        height: 520px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
      }
    }
  }

  .home_first_about {
    margin: 40px auto 0 auto;
    width: 1200px;
    padding: 0 20px;
    display: flex;

    .home_first_about_img {
      overflow: hidden;
      margin-left: 15px;
      width: 416px;

      img {
        display: block;
        cursor: pointer;
      }

      .home_first_about_img_list {
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: 0;
        line-height: 30px;
        padding: 10px 24px;

        .home_first_about_img_list_name {
          font-size: 14px;
          position: relative;
          padding-left: 20px;
          color: #333;
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 控制文本溢出时隐藏超出部分 */
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .home_first_about_img_list_name:last-child {
          border: none;
        }

        .home_first_about_img_list_name::before {
          content: "";
          background-color: #000;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }

    .home_first_about_img:first-child {
      margin-left: 0;
    }
  }
}

.development_more {
  padding: 45px 0 60px 0;

  .development_more_btn {
    cursor: pointer;
    margin: 0 auto;
    font-weight: 500;
    font-size: 28px;
    color: #ffffff;
    line-height: 106px;
    text-align: center;
    width: 1540px;
    height: 106px;
    background: linear-gradient(
      270deg,
      rgba(220, 196, 196, 0),
      rgba(220, 196, 196, 0.5),
      rgba(218, 189, 189, 0)
    );
    font-weight: 900;
    font-family: 黑体;
    font-size: 36px;
    color: #6d2c2c;
    letter-spacing: 1px;
  }
}

.swiper-container {
  --swiper-theme-color: #e11515;
}
.one {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0s infinite;
}
.two {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0.4s infinite;
}
.three {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0.8s infinite;
}
@keyframes donghua {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
